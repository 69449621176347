import { connectAxios } from "./connectAxios";

export async function goKiwoomPay(payReqInfo, user, giwan, prev){
    const payForm       = document.getElementById('KIWOOM_PAYINFO');
    payForm.acceptCharset = "EUC-KR";

    //값 세팅 =================================
    const priceAmount   = payReqInfo.priceAmount.replaceAll(",","");
    
    //결제수단
    let payCd = "";

    if(payReqInfo.paymethod === "creditCard"){
        payCd = "CARDK";
    } else if(payReqInfo.paymethod === "naverPay"){
        payCd = "NAVERPAY";
    } else if(payReqInfo.paymethod === "kakaoPay"){
        payCd = "KAKAOPAY";
    } else if(payReqInfo.paymethod === "samsungPay"){
        payCd = "SAMSUNGPAY";
    } else if(payReqInfo.paymethod === "paycoPay"){
        payCd = "PAYCO";
    } else if(payReqInfo.paymethod === "phoneNumber"){
        payCd = "MOBILE";
    }

    const server = `${process.env.REACT_APP_PAY_PLATFORM}` === "service" ? 'LIVE' : 
    'DEV';

    let fileName    = "";
    let kiwoomMid   = "";
    if(server === "DEV"){
        fileName = "https://apitest.kiwoompay.co.kr/pay/link";
        kiwoomMid = "CTS18542";
    }else if(server === "LIVE"){
        fileName = "https://api.kiwoompay.co.kr/pay/link";
        kiwoomMid = "CHJ82070";
    }

    let failurl     = `${process.env.REACT_APP_FRONT_BASE_URL}/main`;

    //필수사항 ==================================
    //서버
    
    createInput("SERVER", server);

    //서버환경
    createInput("TYPE", "M");
    
    createInput("PAYMETHOD", payCd);

    //상점아이디
    createInput("CPID", kiwoomMid);

    //상품구분 (1: 디지털 2: 실물)
    createInput("PRODUCTTYPE", "1");

    //주문번호
    createInput("ORDERNO", payReqInfo.orderNo);

    //결제 금액
    createInput("AMOUNT", priceAmount);

    //상품명
    let jobNameFull     = prev === "send" ? payReqInfo.jobName + " 전송" : payReqInfo.jobName;
    
    createInput("PRODUCTNAME", jobNameFull);

    //상품코드
    createInput("PRODUCTCODE", payReqInfo.jobNo);

    //고객 ID
    createInput("USERID", user.userMail === undefined ? user.userId : user.userMail) ;
    
    //공통 선택사항 =====================================

    //결제 성공 URL
    createInput("HOMEURL", `${process.env.REACT_APP_FRONT_BASE_URL}/ec-pay/return`);

    //결제 실패 URL
    createInput("FAILURL", failurl);

    //결제 취소 URL
    createInput("CLOSEURL", failurl);

    //고객 메일
    createInput("EMAIL", user.userMail === undefined ? "" : user.userMail);

    //고객명
    createInput("USERNAME", user.userName);

    // 테스트 데이터 (https)
    // let hashData        = "bbe68dbaeab5763aa0b606cd4796341b5971f4cbeb99906a75abd95ba6c0d623^MOBILE^KiwoomPay^021159^M3332b33c794a0fc86b24899f1933e616a947ff7usb^TP01ffa67a43021c7b9284584a0b67cfc7787f9db0636da30104b66b156bc542e4^yh_starlight@naver.com^Y";

    //https에서만 됨
    let hashData    = await createHashData(user.tpid, payReqInfo.orderNo, priceAmount);
    let dataTmp     = "";
    let serviceTy   = "";

    if("issue" === prev) {
        dataTmp = "^" + payReqInfo.jobNo + "^" + payReqInfo.purpose + "^" + payReqInfo.receiver + "^" + user.userName;
        serviceTy = "MOBILE";

    } else if ("send" === prev) {
        //fax 1,2 나눠야함
        const faxNo1 = payReqInfo.destination.slice(0, 3);
        const faxNo2 = payReqInfo.destination.slice(3);
        const recTel = payReqInfo.receiveTel === undefined ? '' : payReqInfo.receiveTel;

        dataTmp = "^" + payReqInfo.purpose + "^" + payReqInfo.receiverFax + "^" + recTel + "^" + "1" + "^" + payReqInfo.jobName + "^" + faxNo1 + "^" + faxNo2 + "^" + payReqInfo.jobNo;
        serviceTy = "FAX";
    }

    hashData        = hashData + "^" + serviceTy + "^" + "KiwoomPay" + "^" + giwan.giwanNo + "^" + payReqInfo.minNo + "^" + user.tpid  + "^" + (user.userMail === undefined ? "GUEST" : user.userMail) + "^" + (payReqInfo.korYN === "K" ? "Y" : "N") + "^" + payReqInfo.buyerTel + dataTmp;

    //예약항목 암호화
    await connectAxios.get('/encrypt-reserve', {
        params: {
            reservedString : hashData
        }
        })
        .then(function(response){
        const result = response.data;
        hashData = result.encReservedStr;
    });

    //예약항목
    createInput("RESERVEDSTRING", hashData);

    //예약항목
    createInput("RESERVEDSTRINGTEMP", hashData);
    
    payForm.action = fileName;
    payForm.target = "_self";
    payForm.submit();
};

function createInput(name, value){
    const payForm   = document.getElementById('KIWOOM_PAYINFO');

    let inputInfo   = document.createElement('input');
    inputInfo.type  = "hidden";
    inputInfo.name  = name;
    inputInfo.value = value;
    payForm.appendChild(inputInfo);
}

//https에서만 사용가능
async function createHashData(tpid, orderNo, amount) {

    const data      = "TPID=" + tpid + "&ORDERNO=" + orderNo + "&AMOUNT=" + amount;
    const encoder   = new TextEncoder();
    const sb        = encoder.encode(data);

    const hashbf    = await crypto.subtle.digest('SHA-256', sb);

    const hashar    = Array.from(new Uint8Array(hashbf));
    const hashhex   = hashar.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashhex.toLowerCase();
}