import React, { useEffect, useState } from "react";
import { connectAxios } from "../../module/connectAxios";
import Loadings from "../../components/common/Loading";
import { useNavigate } from "react-router";
import OneButtonPopup from "../../components/common/Popup/OneButtonPopup";
import { getDecryptParams } from "../../module/MAES256";

const MMECPAYR = () => {
  const [popupText, setPopupText]             = useState('');
  const [showPopup, setShowPopup]             = useState(false);
  const [isLoading, setIsLoading]             = useState(true);
  const navigate                              = useNavigate();

  const params        = new URLSearchParams(window.location.search);

  const user      = JSON.parse(sessionStorage.getItem('userInfo'));
  const giwan     = JSON.parse(sessionStorage.getItem('giwanInfo'));
  const payReqInfo= JSON.parse(sessionStorage.getItem('payReqInfo'));
  
  //I-06. 증명서 발급

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  async function getIssue() {
    await sleep(5000);
    navigate(`/ec-pay2`);
  }

  //I-08. 학적여부 확인
  async function getHakjukChk() {
    const response = await connectAxios.get('/hakjuk-chk', {
      params: {
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo,
        tpid          : user.tpid,
        userMail      : user.userMail,
        userId        : user.userId,
        hakbun        : user.hakbun !== undefined ? user.hakbun : user.hakbunTemp,
        userBirth     : user.userBirth,
        userName      : user.userName,
        hakwi         : user.hakwi
      }
    });
    return response.data.hakbun;
  };

  //I-01. 학적정보 확인
  async function getHakjuk(hakbun) {
    const response = await connectAxios.get('/hakjuk', {
      params: {
        lgnQryType    : giwan.lgnQryType,
        giwanNo       : giwan.giwanNo,
        hakbun        : hakbun,
        userBirth     : user.userBirth,
        userName      : user.userName,
        hakwi         : user.hakwi
      }
    });
    return response.data.hakjukInfo.ename;
  }  

  async function goNext() {
    if(payReqInfo.prev === "issue"){
      await getIssue();
    } else if (payReqInfo.prev === "send"){
      if(payReqInfo.sendType === "W"){
        const hakbun = await getHakjukChk();
        const userEname = await getHakjuk(hakbun);

        putPayInfo(userEname);
      } else {
        setIsLoading(false);
        setShowPopup(true);
        setPopupText('증명서 전송이 완료되었습니다.');
      }
    }
  }

  //B-04. 증명서 전송
  async function putPayInfo(userEname) {
    const response = await connectAxios.post('/send-wallet',{
      sendType    : payReqInfo.sendType,
      lgnQryType  : giwan.lgnQryType,
      minNo       : payReqInfo.minNo,
      orderNo     : payReqInfo.orderNo,
      giwanNo     : giwan.giwanNo,
      tpid        : user.tpid,
      buyerTel    : payReqInfo.buyerTel,
      receiveTel  : payReqInfo.receiveTel,
      jobNo       : payReqInfo.jobNo,
      receiver    : payReqInfo.receiver,
      destination : payReqInfo.destination,
      korYN       : payReqInfo.korYN,
      ename       : userEname,
      receiverFax : payReqInfo.receiverFax,
      purpose     : payReqInfo.purpose,
      paymethod   : payReqInfo.paymethod,
    });

    if(response.data.resultCode === "200"){
      setIsLoading(false);
      setShowPopup(true);
      setPopupText('증명서 전송이 완료되었습니다.');
    } else if (response.data.resultCode === "201"){
      setIsLoading(false);
      setShowPopup(true);
      setPopupText('증명서 전송에 실패했습니다.');
    }
  }

  useEffect(()=> {
    const chkPay = async () => {
      try{
        await connectAxios.get('chk-pay', {
          params: {
            orderNo : payReqInfo.orderNo
          }
        }).then((response) => {
          const result = response.data;
          if (result.payResultCode === "0000" && result.payChkCntYn === "Y"  && result.payChkYn === "N"){
            goNext();
          } else {
            setIsLoading(false);
            setShowPopup(true);
            setPopupText('결제 중 오류가 발생했습니다.');
          }
        });
      } catch (error) {
        setIsLoading(false);
        setShowPopup(true);
        setPopupText('결제 중 오류가 발생했습니다.');
      }
    }

    chkPay();
  }, []);  

  const handleCancel = () => {
    setShowPopup(false);
  //팝업
    if (payReqInfo.prev === "issue") {
      navigate("/main");
    } else if (payReqInfo.prev === "send") {
      navigate("/basket-list", { state : { prev : 'send' }});
    }
  };

  // 새로고침시 다시 랜더링
  useEffect(() => {
    // 브라우저 새로고침 이벤트 핸들러
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // 이 부분은 브라우저마다 다르게 동작할 수 있습니다.
    };

    // 이벤트 핸들러 등록
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 핸들러 제거
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // setContent을 의존성 배열에 추가

  return (
    <React.Fragment>
    {isLoading && (
      <React.Fragment>
        <Loadings
        loadingTxt="증명서 결제 중입니다."
        loadingTxt1="잠시만 기다려주세요."
        />
      </React.Fragment>
    )}
    {showPopup && (
      <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
      )}
    </React.Fragment>
  );
}
export default MMECPAYR;