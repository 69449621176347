import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from '../../components/common/TopNavBar';
import { IoIosCheckmark } from 'react-icons/io';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import 'react-tooltip/dist/react-tooltip.css'
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import { connectAxios } from '../../module/connectAxios';


const MMECPAY1 = () => {
  const navigate                          = useNavigate();
  const [phone, setPhone]                 = useState('');
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const { state }                         = useLocation();
  const { jobName, issueInfo }            = state;
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [isChecked, setIsChecked]         = useState(false);
  const [isOneButtonPopup, setIsOneButtonPopup] = useState(false);

  const user        = JSON.parse(sessionStorage.getItem('userInfo'));
  const phoneRef    = useRef(phone);
  
  useEffect(()=>{
    setPhone(user.userTel);
  },[])

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhone = phoneNumber.replace(/\D/g, '').slice(0, 15);
    setPhone(sanitizedPhone);
  };

  const isButtonDisabled = !isChecked || !phone || phone.length < 11 ;
  const buttonId = isButtonDisabled ? 'buttonDisabled' : '';

  const handlePaymentClick = async () => {

    const response = await connectAxios.get('/chk-key', {
      params: {
        orderNo : issueInfo.orderNo
      }
    });
    

    if(response.data.resultCode === "200"){
      phoneRef.current = phone;
      navigate('/ec-pay3' , { state : { phone : phone, issueInfo : issueInfo, jobName : jobName , paymentMethod: paymentMethod, prev : "issue"}})
    } else if (response.data.resultCode === '201') {
      setIsOneButtonPopup(true);
    }
  };

   const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleContent = () => {
    setIsContentOpen(!isContentOpen);
  };

  const goMain = () => {
    navigate('/main');
  }

  return (
    <div>
    <TopNavBar/>
    <div className="select-screen">
      <div className="section-title">
        <h2 className='foam-title'>증명서 결제정보</h2>
      </div>

      <div className="info-box">
        <div className="info-row">
          <div className="info-label">증명서 종류</div>
          <div className="info-value">{jobName}</div>
        </div>
        <div className="info-row">
          <div className="info-label">통수</div>
          <div className="info-value">1 통</div>
        </div>
        
        <div className="info-row">
 
          <div className="info-label">총 금액</div>
          <div className="info-cont">
            <div className="info-value">{issueInfo.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</div>
             <ReactTooltip
               id="tooltip1"
               effect="solid"
               place="bottom"
                type="info"
//                 getContent={dataTip =>
//              <div className="info-value-detail"
// >해당 수수료는 증명서 수수료와 대행수수료 (전자생성료 포함)을 합한 금액입 니다.</div>}
           >
                  <div className="info-value-detail"> 
 증명서 수수료({issueInfo.jobPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원) + 대행 수수료({issueInfo.transPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원)<br/>
<br />* 증명서 수수료 : 발급처(학교, 기관)에서 부과하는 수수료
<br />* 대행 수수료 (전자생성료 포함) : 인터넷 서비스 이용 수수료
          </div> 
          </ReactTooltip>


            <span  data-tooltip-id="tooltip1" data-tip data-for="tooltip1" ><AiFillQuestionCircle className='question'/> </span>
           
         </div>
        </div>

        <div className="info-row">
          <div className="info-label">용도</div>
          <div className="info-value">{issueInfo.purpose}</div>
        </div>
        <div className="info-row">
          <div className="info-label">제출처</div>
          <div className="info-value"> {issueInfo.receiver}</div>
        </div>
      </div>

      <div className="flex-area">
        <h2 className='foam-title'>결제자 정보</h2>
        <label htmlFor="input15" className='blind'> 결제자 정보 입력</label>

        <input
          type="tel"
          onChange={handlePhoneChange}
          value={phone}
          id="dropdown-phone-input input15"
          placeholder='휴대폰 번호를 (-) 없이 입력해주세요.'
        />
         {phone.length > 0 && phone.length < 11 && (
    <p className="error-message" id='marginBottom05'>* 휴대폰 번호는 11자리를 입력해주세요.</p>
  )}
        <span className='tip-text' id='no-padding'>* 본인 명의의 휴대폰만 가능</span>
        <h2 className='foam-title'>결제방식</h2>
       
        </div>

        <div className="select-btn-container" id='marginbottom05'>
          <button
            onClick={() => handlePaymentMethodChange('creditCard')}
            className={paymentMethod === 'creditCard' ? 'select-btn active' : 'select-btn'}
          >
            <p className={paymentMethod === 'creditCard' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'creditCard' && <IoIosCheckmark />}
              </p>
              <p>신용카드</p></button>
          <button
            onClick={() => handlePaymentMethodChange('naverPay')}
            className={paymentMethod === 'naverPay' ? 'select-btn active' : 'select-btn'}
              >
            <p className={paymentMethod === 'naverPay' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'naverPay' && <IoIosCheckmark />}
              </p>
              <p>네이버페이</p>
          
          </button>    
      </div>

      <div className="select-btn-container" id='marginbottom05'>
          <button
            onClick={() => handlePaymentMethodChange('kakaoPay')}
            className={paymentMethod === 'kakaoPay' ? 'select-btn active' : 'select-btn'}
          >
            <p className={paymentMethod === 'kakaoPay' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'kakaoPay' && <IoIosCheckmark />}
              </p>
              <p>카카오페이</p></button>
          <button
            onClick={() => handlePaymentMethodChange('samsungPay')}
            className={paymentMethod === 'samsungPay' ? 'select-btn active' : 'select-btn'}
              >
            <p className={paymentMethod === 'samsungPay' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'samsungPay' && <IoIosCheckmark />}
              </p>
              <p>삼성페이</p>
          </button>
      </div>

      <div className="select-btn-container" id='marginbottom1'>
          <button
            onClick={() => handlePaymentMethodChange('paycoPay')}
            className={paymentMethod === 'paycoPay' ? 'select-btn active' : 'select-btn'}
          >
            <p className={paymentMethod === 'paycoPay' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'paycoPay' && <IoIosCheckmark />}
              </p>
              <p>페이코</p></button>
          <button
            onClick={() => handlePaymentMethodChange('phoneNumber')}
            className={paymentMethod === 'phoneNumber' ? 'select-btn active' : 'select-btn'}
              >
            <p className={paymentMethod === 'phoneNumber' ? 'select-round active' : 'select-round'}>
                  {paymentMethod === 'phoneNumber' && <IoIosCheckmark />}
              </p>
              <p>휴대폰</p>
          </button>
      </div>

      <div className='toggle'>
        <div className="toggle-box">
          <div className="toggle-top">
      <input type="checkbox" id='square-check' checked={isChecked} onChange={handleCheckboxChange} />
      <div className="toggle-header" onClick={toggleContent}>
          <p>  개인정보 수집 및 이용 동의 <span className='select-tip'> (필수)</span></p>
      </div>
      </div>
      <div  onClick={toggleContent}>
      {isContentOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div></div>
      {isContentOpen && (
        <div className="toggle-body">
    한국정보인증(주)은 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.
        <br /><br />
        
        개인정보 수집 및 이용에 대한 안내<br />
        1. 목적 : 결제이력 보관 및 제증명 신청, 발급 장애 등이 발생할 경우 연락하기 위한 목적<br />
        2. 항목 : 휴대폰 번호<br />
        3. 보유기간 : 결제일로부터 5년(전자상거래 등에서의 소비자보호에 관한 법률에 따름)<br />
        <br />
        위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 증명서 발급이 제한될 수 있습니다.
       
        </div>
      )}
    </div>

  
      </div>
      <ClickButtonBigA onClick={() => handlePaymentClick()} 
      buttonText="결제하기"   
      buttonId={`${buttonId}`}  
      disabled={isButtonDisabled}
      className="change-btn"
      />

       {isOneButtonPopup && (
        <OneButtonPopup
        onClick={()=> {goMain()}}  
        popupText={'결제에 실패했습니다.'} 
        buttonText="확인" />
      )}
  </div>
  );
};

export default MMECPAY1;